import React from "react";
import "./SureDelete.pages.css";

const SureDelete = () => {
  return (
    <div className="sure-delete">
      <p>Are you sure you want to delete?</p>
    </div>
  );
};
export default SureDelete;
